const is_dev = process.env.NODE_ENV === 'development'
const host = is_dev ? 'https://api.xinzixun.org' : 'https://api.xinzixun.org'
const version = 'v3'
const base = version ? `${host}/${version}/` : `${host}/`
const socket = is_dev ? 'ws://swooleserve.gs-z.cn' : 'ws://swooleserve.gs-z.cn'
const base_img = `${is_dev ? 'https://api.xinzixun.org' : host}/static/images/`
const icon = 'https://at.alicdn.com/t/font_644872_3fw0v4op853.js'
const edition = '3.0.1'

let envConfig = {
    base: process.env.project_base,
    host: process.env.project_host,
    static: process.env.project_static,
    socket: process.env.project_socket,
    icon: process.env.project_icon,
    edition: process.env.project_version
}

const config = {

    project: "lottery",
    version: "v3/",
    environment: navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) ? 'mobile' : 'pc',
    equipment: process.env.NODE_ENV,
    is_dev,

    get base() {
        return envConfig.base ? envConfig.base : base
    },
    get host() {
        return envConfig.host ? envConfig.host : host
    },
    get base_img() {
        return envConfig.static ? envConfig.static : base_img
    },
    get socket() {
        return envConfig.socket ? envConfig.socket : socket
    },
    get icon() {
        return envConfig.icon ? envConfig.icon : icon
    },
    get edition() {
        return envConfig.edition ? envConfig.edition : edition
    }
}
export default config

